import React, { useState } from "react";

import useCloseModal from "hooks/modal/useCloseModal";

import ModalContainer from "../ModalContainer";

import s from "./NoNftModal.module.scss";

interface Props {
  open: boolean;
  close?: () => void;
}

function NoNftModal({ open, close = undefined }: Props) {
  const { isClosing, onClose } = useCloseModal(close);

  return (
    <ModalContainer
      open={open}
      onClose={close ? onClose : () => {}}
      isClosing={isClosing}
    >
      <div className={s.modalWindowWrapper}>
        <div className={s.modalWindow}>
          <div className={s.summeryWrapper}>
            <div className={s.summery}>
              <div className={s.title}>Oops!</div>
            </div>
          </div>
          <div className={s.content}>
            <div className={s.textBlock}>
              <p>
                Oops, there is no NFT Pass in your wallet. Please get an NFT
                Pass on OpenSea to receive your gift.
              </p>
            </div>
            <a
              href="https://opensea.io/collection/trace-genesis-nft-pass"
              className={s.button}
            >
              Opensea
            </a>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default NoNftModal;
