import React, { useState } from "react";
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";
import { enqueueSnackbar } from "notistack";

import useCloseModal from "hooks/modal/useCloseModal";

import inputBackground from "images/inputBackground.jpg";

import ModalContainer from "../ModalContainer";

import s from "./EmailRequestModal.module.scss";

const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL || "";

const regexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  open: boolean;
  close: () => void;
}

function EmailRequestModal({ open, close }: Props) {
  const { isClosing, onClose } = useCloseModal(close);
  const [emailText, setEmailText] = useState("");

  // const [blockButton, setBlockButton] = useState(false);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailText(e.target.value);
  };

  const onSubmit =
    (subscribe: (data: EmailFormFields) => void) =>
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!emailText.match(regexp)) {
        enqueueSnackbar({
          variant: "trace",
          customTitle: "Error",
          customMessage: "Email is not valid",
          type: "error",
        });
        return;
      }
      subscribe({ EMAIL: emailText });
      // setBlockButton(true);
      // setTimeout(() => {
      //   setBlockButton(false);
      // }, 5000);

      enqueueSnackbar({
        variant: "trace",
        customTitle: "Email has been sended!",
        // customMessage: `We sended message to ${emailText}, confirm it to stay tuned!`,
        customMessage: `Stay tuned for updates!`,
        type: "correct",
      });
      onClose();
    };

  return (
    <ModalContainer open={open} onClose={() => {}} isClosing={isClosing}>
      <div className={s.modalWindowWrapper}>
        <div className={s.modalWindow}>
          <div className={s.summeryWrapper}>
            <div className={s.summery}>
              <div className={s.title}>Hooray!</div>
              {/* <button type="button" className={s.close} onClick={onClose}>
                <div className={s.iconClose}>
                  <span className={s.line} />
                  <span className={s.line} />
                </div>
              </button> */}
            </div>
          </div>
          <div className={s.content}>
            <div className={s.textBlock}>
              <p>
                You successfully received the gift!
                <br />
                <br /> To see it, please sign up on our Gem platform. We will
                launch it soon. Stay tuned for updates! Keep in touch!
              </p>
            </div>
            <div className={s.bottomPart}>
              <img
                src={inputBackground}
                alt="metaverse background"
                className={s.background}
              />
              <MailchimpSubscribe
                url={MAILCHIMP_URL}
                render={({ subscribe }) => {
                  return (
                    <form
                      className={s.emailInput}
                      onSubmit={onSubmit(subscribe)}
                    >
                      <input
                        type="text"
                        className={s.input}
                        value={emailText}
                        onChange={onEmailChange}
                        placeholder="Enter your e-mail"
                      />
                      <button
                        type="submit"
                        // className={`${s.submit} ${
                        //   blockButton ? s.blocked : ""
                        // }`}
                        // disabled={blockButton}
                        className={s.submit}
                      >
                        Send
                      </button>
                    </form>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default EmailRequestModal;
